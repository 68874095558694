@use "/src/styles/variables/dims.scss";
@use "/src/styles/variables/colors.scss";

root {
}

.tooltip {
  max-width: 280px;

  &--disabled {
    display: none;
  }
}

.titleWrapper {
  button,
  a {
    color: colors.$primary-light;
  }
}

.innerSpan {
  pointer-events: all;
}
